import { useContext, useRef } from 'react';

import './user-list.css';

import { SnackbarContext } from '../../provider/SnackbarProvider';
import { SummaryCard } from '../summary-card/summary-card';
import { getUsers } from '../../requests/users';
import { UsersContext } from '../../provider/UsersProvider';
import { UserPreview } from '../../contracts/users';
import { AuthContext } from '../../auth/AuthProvider';

export function UserList({ className = '' }: { className?: string }) {
  const { xCsrfToken } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);
  const { addUsers, totalUsers, users } = useContext(UsersContext);

  const userListRef = useRef<HTMLUListElement>(null);

  function handleScroll(e: React.UIEvent<HTMLUListElement, UIEvent>) {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.clientHeight -
          e.currentTarget.scrollTop
      ) < 1 &&
      users.length < totalUsers
    ) {
      getUsers(xCsrfToken as string, `${users.length}`)
        .then(async (response) => {
          if (response.ok) {
            const responseData: { users: UserPreview[] } =
              await response.json();
            addUsers(responseData.users);
            toggleSnackbar('Successfully retrieved users.');
          } else {
            console.error('An error occurred', await response.json());
            toggleSnackbar('Failed to retrieve users.');
          }
        })
        .catch((e) => {
          console.error(e);
          toggleSnackbar('An error occurred, please try again later.');
        });
    }
  }

  return (
    <div className={'users' + className}>
      <h1 className="users__h1">Users</h1>
      <ul
        className="users__user-list"
        ref={userListRef}
        onScroll={handleScroll}
      >
        {users.map(({ userId, fullName, balance }) => (
          <li className="users__user-list__li" key={userId}>
            <SummaryCard label={fullName} value={balance} />
          </li>
        ))}
      </ul>
    </div>
  );
}
