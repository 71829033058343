import { useContext, useState } from 'react';
import moment from 'moment';

import './orders.css';

import { ProductList } from '../../components/product-list/product-list';
import { Layout } from '../../layout/layout';
import { OrderDetails } from './order-detail';
import { AddOrder } from './add-order';
import { OrderRequest } from '../../contracts/order';

import { makeOrder, restockOrder } from '../../requests/orders';

import { SnackbarContext } from '../../provider/SnackbarProvider';

export function Orders() {
  const { toggleSnackbar } = useContext(SnackbarContext);

  const [restock, setRestock] = useState(false);

  const handleCheckChange = (restock: boolean) => {
    setRestock(restock);
  };

  const today = moment().format('MMM Do YY');
  const [title] = useState(today);
  const [total, setTotal] = useState(0);

  const [items, setItems] = useState<OrderRequest[]>([]);

  const clearItems = () => {
    setItems([]);
    setTotal(0);
  };

  async function submitOrder() {
    const orders = items.map(
      ({ productId, userId, dateOrdered, revenue, quantity }) => ({
        productId,
        userId,
        dateOrdered,
        revenue,
        quantity,
      })
    );

    if (orders.length === 0) {
      toggleSnackbar('No items added to order. Cannot submit');
      return;
    }

    const xCsrfToken = localStorage.getItem('x-csrf-token');
    try {
      const response = await makeOrder(orders, xCsrfToken as string);
      const responseData: { message: string } = await response.json();

      if (response.ok) {
        toggleSnackbar(responseData.message);
      } else {
        console.error('Failed to make order', responseData.message);
        toggleSnackbar(`Failed to make order: ${responseData.message}`);
      }
      clearItems();
    } catch (e) {
      console.error(e);
      toggleSnackbar('An error occurred, please try again later.');
    }
  }

  async function submitRestockOrder() {
    const orders = items.map(
      ({
        productId,
        userId,
        dateOrdered,
        datePaid,
        orderType,
        purchaseLocation,
        quantity,
        cost,
        revenue,
        profit,
        value,
      }) => ({
        productId,
        userId,
        dateOrdered,
        datePaid,
        orderType,
        purchaseLocation,
        quantity,
        cost,
        revenue,
        profit,
        value,
      })
    );

    if (orders.length === 0) {
      toggleSnackbar('No items added to order. Cannot submit');
      return;
    }

    const xCsrfToken = localStorage.getItem('x-csrf-token');
    try {
      const response = await restockOrder(orders, xCsrfToken as string);
      const responseData: { message: string } = await response.json();

      if (response.ok) {
        toggleSnackbar(responseData.message);
      } else {
        console.error(
          'Failed to register restocking order',
          responseData.message
        );
        toggleSnackbar(
          `Failed to register restocking order: ${responseData.message}`
        );
      }
      clearItems();
    } catch (e) {
      console.error(e);
      toggleSnackbar('An error occurred, please try again later.');
    }
  }

  const addItem = (item: OrderRequest) => {
    setItems((prev) => [...prev, item]);
    setTotal((prev) => prev + ((item.revenue || item.cost) ?? 0));
  };

  return (
    <Layout>
      <div className="orders">
        <ProductList className=" flex" />
        <section className="orders__section">
          <OrderDetails
            items={items}
            title={title}
            total={total}
            cancelOrder={clearItems}
            confirmOrder={restock ? submitRestockOrder : submitOrder}
          />
          <AddOrder
            addItem={addItem}
            handleCheck={handleCheckChange}
            disableCheckbox={items.length > 0}
          />
        </section>
      </div>
    </Layout>
  );
}
