import './login.css';
import { useContext } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';

import { AuthContext } from '../../auth/AuthProvider';
import { Layout } from '../../layout/layout';
import { SubmitButton } from '../../components/button/submit-button';
import { SnackbarContext } from '../../provider/SnackbarProvider';

import { login } from '../../requests/auth';

const formSchema = object({
  email: string().required('Email required'),
  password: string().required('Password required'),
});

interface Values {
  email: string;
  password: string;
}

export function Login() {
  const { authenticate } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);

  async function handleLogin(values: Values) {
    try {
      const response = await login(values);
      const responseData: { message: string } = await response.json();

      if (response.status === 200) {
        authenticate(response.headers.get('x-csrf-token') as string);
        toggleSnackbar(responseData.message);
      } else {
        console.error('Failed to login', responseData.message);
        toggleSnackbar(`Failed to login: ${responseData.message}`);
      }
    } catch (e) {
      console.error(e);
      toggleSnackbar('An error occurred, please try again later.');
    }
  }

  return (
    <Layout>
      <div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={formSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className="login__form">
              <h2>Login</h2>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field type="text" name="email" />
                <ErrorMessage name="email" component="div" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field type="password" name="password" />
                <ErrorMessage name="password" component="div" />
              </div>
              <SubmitButton label="Login" disabled={isSubmitting} />
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}
