import { host } from './products';
import { OrderRequest } from '../contracts/order';

export function makeOrder(orders: OrderRequest[], xCsrfToken: string) {
  return fetch(`${host}/v1/orders`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': xCsrfToken,
    },
    body: JSON.stringify({ orders }),
  });
}
export function restockOrder(orders: OrderRequest[], xCsrfToken: string) {
  return fetch(`${host}/v1/users/admin/orders`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': xCsrfToken,
    },
    body: JSON.stringify({ orders }),
  });
}
