import './layout.css';

import { ReactNode } from 'react';

export function SubLayout({
  children,
  className = '',
}: {
  className?: string;
  children: ReactNode;
}) {
  return <div className={'sub-layout ' + className}>{children}</div>;
}
