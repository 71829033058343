import { useEffect } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';

import { OrderValues } from '../../pages/orders/add-order';

export function CustomerOrderGroup({
  sellingPrice,
}: {
  sellingPrice?: number;
}) {
  const { values, setFieldValue } = useFormikContext<OrderValues>();

  useEffect(() => {
    if (sellingPrice) {
      setFieldValue('value', values.quantity * sellingPrice);
    }
  }, [setFieldValue, values.quantity, sellingPrice]);

  const increment = () => {
    setFieldValue('quantity', values.quantity + 1);
  };

  const decrement = () => {
    if (values.quantity > 0) {
      setFieldValue('quantity', values.quantity - 1);
    }
  };

  return (
    <>
      <div className="orders__add-order__customer-order-group">
        <div className="orders__add-order__customer-order-group__info__quantity">
          <button type="button" onClick={decrement}>
            -
          </button>
          <span>{values.quantity}</span>
          <button type="button" onClick={increment}>
            +
          </button>
        </div>
        <div className="orders__add-order__customer-order-group__info__price">
          <span>X</span>
          <span>{sellingPrice}</span>
        </div>
      </div>
      <ErrorMessage name="quantity" component="div" className="error" />
      <div className="orders__add-order__customer-order-group__total">
        <span>Total</span>
        <span>{values.quantity * (sellingPrice ?? 0)}</span>
      </div>
      <ErrorMessage name="value" component="div" className="error" />
    </>
  );
}
