import { useState, ReactNode, createContext, useEffect } from 'react';

export const SnackbarContext = createContext<{
  show: boolean;
  message: string | null;
  toggleSnackbar: (message: string) => void;
}>({
  show: false,
  message: null,
  toggleSnackbar: function () {},
});

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (show) {
      timeout = setTimeout(() => {
        setShow(false);
        setMessage(null);
      }, 2000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [show]);

  function toggleSnackbar(message: string) {
    setShow(true);
    setMessage(message);
  }

  return (
    <SnackbarContext.Provider
      value={{
        show,
        message,
        toggleSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
