import './button-container.css';

export function ButtonContainer({
  firstLabel,
  secondLabel,
  accept,
  cancel,
}: {
  firstLabel: string;
  secondLabel: string;
  accept?: (input?: any) => void;
  cancel: () => void;
}) {
  return (
    <div className="button-container">
      <button type="button" onClick={cancel}>
        {firstLabel}
      </button>
      <button type="submit" onClick={accept}>
        {secondLabel}
      </button>
    </div>
  );
}
