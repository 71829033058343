import { useParams } from 'react-router-dom';

import './products.css';

import { Layout } from '../../layout/layout';

import { ProductList } from '../../components/product-list/product-list';
import { ProductDetails } from './product-details';
import { SubLayout } from '../../layout/sub-layout';

export function Products() {
  const { productId } = useParams();

  return (
    <Layout>
      <SubLayout className={productId ? 'products-container' : ''}>
        <ProductList />
        {productId && <ProductDetails productId={productId} />}
      </SubLayout>
    </Layout>
  );
}
