import './orders.css';

import { ButtonContainer } from '../../components/button-container/button-container';
import { OrderRequest } from '../../contracts/order';

export function OrderDetails({
  items,
  title,
  total,
  cancelOrder,
  confirmOrder,
}: {
  items: OrderRequest[];
  title: string;
  total: number;
  cancelOrder: () => void;
  confirmOrder: () => void;
}) {
  return (
    <div className="orders__summary">
      <div className="orders__summary__invoice">
        <div className="orders__summary__invoice__title">{title}</div>
        <ul>
          {items.map(({ product, quantity, revenue, productId, cost }) => (
            <li className="orders__summary__invoice__item" key={productId}>
              <span>
                <span>{product}</span>
                <span>{quantity}</span>
              </span>
              <span>{revenue || cost}</span>
            </li>
          ))}
        </ul>
        <div className="orders__summary__invoice__total">
          <p>Money Spent</p>
          <span>{total}</span>
        </div>
      </div>
      <div className="orders__summary__confirmation">
        <p>Is the order complete?</p>
        <ButtonContainer
          firstLabel="Cancel"
          cancel={cancelOrder}
          secondLabel="Confirm"
          accept={confirmOrder}
        />
      </div>
    </div>
  );
}
