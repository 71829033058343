import { useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import { OrderValues } from '../../pages/orders/add-order';

export interface RestockOrderGroupProps {
  sellingPrice?: number;
}

export function RestockOrderGroup({ sellingPrice }: RestockOrderGroupProps) {
  const { setFieldValue, values } = useFormikContext<OrderValues>();

  useEffect(() => {
    if (sellingPrice) {
      setFieldValue('value', values.quantity * sellingPrice);
    }
  }, [setFieldValue, values.quantity, sellingPrice]);

  return (
    <div className="orders__add-order__restock-order-group">
      <div className="orders__add-order__restock-order-group__info__input-group">
        <label htmlFor="quantity">Quantity</label>
        <Field className="input" type="number" name="quantity" id="quantity" />
      </div>
      <ErrorMessage name="quantity" component="div" className="error" />
      <div className="orders__add-order__restock-order-group__info__input-group">
        <label htmlFor="purchaseLocation">Purchase Location</label>
        <Field
          className="input"
          type="text"
          name="purchaseLocation"
          id="purchaseLocation"
        />
      </div>
      <ErrorMessage name="purchaseLocation" component="div" className="error" />
      <div className="orders__add-order__restock-order-group__info__input-group">
        <label htmlFor="cost">Cost</label>
        <Field className="input" type="number" name="cost" id="cost" />
      </div>
      <ErrorMessage name="cost" component="div" className="error" />
      <div className="orders__add-order__restock-order-group__info__input-group">
        <label htmlFor="value">Value</label>
        <Field
          className="input"
          type="number"
          name="value"
          id="value"
          disabled
        />
      </div>
      <ErrorMessage name="value" component="div" className="error" />
    </div>
  );
}
