import './orders.css';

import { useContext, useState } from 'react';
import moment from 'moment';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldInputProps,
  FormikHelpers,
} from 'formik';
import { boolean, number, object, string } from 'yup';

import { OrderRequest } from '../../contracts/order';
import { ButtonContainer } from '../../components/button-container/button-container';
import { ProductPreview } from '../../contracts/products';
import { CustomerOrderGroup } from '../../components/order-input/customer-order-group';
import { RestockOrderGroup } from '../../components/order-input/restock-order-group';

import { ProductsContext } from '../../provider/ProductsProvider';
import { UsersContext } from '../../provider/UsersProvider';

const formSchema = object({
  restock: boolean(),
  productId: string().required('Select a product'),
  userId: string().required('Select a user'),
  quantity: number().required('Quantity must be positive').positive().integer(),
  purchaseLocation: string().when('restock', {
    is: true,
    then: (schema) => schema.required('Enter location'),
    otherwise: (schema) => schema.nullable(),
  }),
  cost: number().when('restock', {
    is: true,
    then: (schema) => schema.required('Cost must be positive').positive(),
    otherwise: (schema) => schema.nullable(),
  }),
  value: number().required('Value must be positive').positive(),
});

export interface OrderValues {
  productId: string;
  userId: string;
  quantity: number;
  purchaseLocation: string;
  cost: number;
  value: number;
  restock: boolean;
}

export function AddOrder({
  addItem,
  disableCheckbox,
  handleCheck,
}: {
  addItem: (item: OrderRequest) => void;
  disableCheckbox: boolean;
  handleCheck: (restock: boolean) => void;
}) {
  const { products } = useContext(ProductsContext);
  const { users } = useContext(UsersContext);

  const [selectedProduct, setSelectedProduct] = useState<ProductPreview | null>(
    null
  );
  const selectProduct = (productId: string) => {
    const itemFound = products.find((item) => item.productId === productId);
    if (itemFound) {
      setSelectedProduct(itemFound);
    }
  };

  const addProduct = (
    {
      cost,
      productId,
      userId,
      purchaseLocation,
      quantity,
      value,
      restock,
    }: OrderValues,
    { resetForm }: FormikHelpers<OrderValues>
  ) => {
    if (selectedProduct) {
      const item = restock
        ? {
            productId,
            userId,
            dateOrdered: moment().format('YYYY-MM-DD'),
            datePaid: null,
            orderType: 'restock',
            purchaseLocation,
            quantity,
            cost,
            revenue: 0,
            profit: 0,
            value,
            product: selectedProduct.productName,
          }
        : {
            dateOrdered: moment().format('YYYY-MM-DD'),
            productId,
            userId,
            product: selectedProduct.productName,
            revenue: value,
            quantity,
          };

      addItem(item);
      resetForm({
        values: {
          productId: '',
          userId: '',
          quantity: 0,
          cost: 0,
          purchaseLocation: '',
          value: 0,
          restock,
        },
      });
    }
  };

  return (
    <div className="orders__add-order__container">
      <Formik
        initialValues={{
          userId: '',
          productId: '',
          quantity: 0,
          cost: 0,
          purchaseLocation: '',
          value: 0,
          restock: false,
        }}
        validationSchema={formSchema}
        onSubmit={addProduct}
      >
        {({ setFieldValue, resetForm, values }) => (
          <>
            {!disableCheckbox && (
              <div>
                <Field name="restock">
                  {({ field }: { field: FieldInputProps<string> }) => (
                    <input
                      {...field}
                      id="restock"
                      type="checkbox"
                      onChange={() => {
                        handleCheck(!field.value);
                        setFieldValue('restock', !field.value);
                      }}
                      defaultChecked={values.restock}
                    />
                  )}
                </Field>
                <label htmlFor="restock">Restock</label>
              </div>
            )}

            <Form className="orders__add-order">
              <div>
                <div className="orders__add-order__title">
                  <Field
                    className="orders__add-order__title__select"
                    name="userId"
                    as="select"
                  >
                    {users.map((item) => (
                      <option key={item.userId} value={item.userId}>
                        {item.fullName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="userId"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="orders__add-order__info">
                  <Field name="productId">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <select
                        {...field}
                        onChange={(e) => {
                          selectProduct(e.target.value);
                          setFieldValue('productId', e.target.value);
                        }}
                      >
                        {products.map((item) => (
                          <option key={item.productId} value={item.productId}>
                            {item.productName}
                          </option>
                        ))}
                      </select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="productId"
                    component="div"
                    className="error"
                  />
                  {values.restock ? (
                    <RestockOrderGroup
                      sellingPrice={selectedProduct?.sellingPrice}
                    />
                  ) : (
                    <CustomerOrderGroup
                      sellingPrice={selectedProduct?.sellingPrice}
                    />
                  )}
                </div>
                <div className="orders__add-order__controls">
                  <ButtonContainer
                    firstLabel="Clear"
                    cancel={() =>
                      resetForm({
                        values: {
                          productId: '',
                          userId: '',
                          quantity: 0,
                          cost: 0,
                          purchaseLocation: '',
                          value: 0,
                          restock: values.restock,
                        },
                      })
                    }
                    secondLabel="Add"
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
