import { Layout } from '../layout/layout';

export function Home() {
  return (
    <Layout>
      <h1 className="title">Alcohol Management Service</h1>
      <p>
        This is a service to monitor the stock levels for an inventory of
        alcoholic beverages
      </p>
    </Layout>
  );
}
