import './App.css';
import { RouterProvider } from 'react-router-dom';

import { router } from './router/router';

import AuthProvider from './auth/AuthProvider';
import { SnackbarProvider } from './provider/SnackbarProvider';
import { ProductsProvider } from './provider/ProductsProvider';
import { UsersProvider } from './provider/UsersProvider';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ProductsProvider>
          <UsersProvider>
            <SnackbarProvider>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </UsersProvider>
        </ProductsProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
