export const host = process.env.REACT_APP_SERVER_URL as string;

export function getProducts(
  xCsrfToken: string,
  pageOffset: string = '0',
  pageSize: string = '50'
) {
  return fetch(
    `${host}/v1/products?pageSize=${pageSize}&pageOffset=${pageOffset}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': xCsrfToken,
      },
    }
  );
}

export function countProducts(xCsrfToken: string) {
  return fetch(`${host}/v1/products/total`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': xCsrfToken,
    },
  });
}

export function getPoductDetails(xCsrfToken: string, productId: string) {
  return fetch(`${host}/v1/users/admin/products/${productId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': xCsrfToken,
    },
  });
}
