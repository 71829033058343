import {
  useState,
  ReactNode,
  createContext,
  useEffect,
  useContext,
  useRef,
} from 'react';

import { UserPreview } from '../contracts/users';
import { countUsers, getUsers } from '../requests/users';
import { AuthContext } from '../auth/AuthProvider';
import { SnackbarContext } from './SnackbarProvider';

export const UsersContext = createContext<{
  users: UserPreview[];
  totalUsers: number;
  populateUserList: (items: UserPreview[]) => void;
  addUsers: (items: UserPreview[]) => void;
}>({
  users: [],
  totalUsers: 0,
  populateUserList: function () {},
  addUsers: function () {},
});

export function UsersProvider({ children }: { children: ReactNode }) {
  const { xCsrfToken } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);

  const [users, setUsers] = useState<UserPreview[]>([]);

  function populateUserList(items: UserPreview[]) {
    setUsers(items);
  }

  function addUsers(items: UserPreview[]) {
    setUsers((prev) => [...prev, ...items]);
  }

  const totalUsersRef = useRef<number | null>(null);

  useEffect(() => {
    if (totalUsersRef.current !== null) {
      return;
    }

    countUsers(xCsrfToken as string).then(async (response) => {
      if (!response.ok) {
        console.error('An error occurred', await response.json());
        toggleSnackbar('Failed to count the users.');
        return;
      }

      const responseData: { total: number } = await response.json();
      totalUsersRef.current = responseData.total;
      toggleSnackbar('Successfully retrieved users.');
    });
  }, [xCsrfToken]);

  useEffect(() => {
    getUsers(xCsrfToken as string)
      .then(async (response) => {
        if (response.ok) {
          const responseData: { users: UserPreview[] } = await response.json();
          populateUserList(responseData.users);
          toggleSnackbar('Successfully retrieved users.');
        } else {
          console.error('An error occurred', await response.json());
          toggleSnackbar('Failed to retrieve users.');
        }
      })
      .catch((e) => {
        console.error(e);
        toggleSnackbar('An error occurred, please try again later.');
      });
  }, [xCsrfToken]);

  return (
    <UsersContext.Provider
      value={{
        users,
        totalUsers: totalUsersRef.current ?? 0,
        populateUserList,
        addUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
