import { createBrowserRouter } from 'react-router-dom';

import { GuardedRoute, UnGuardedRoute } from '../auth/RouteGuards';

import ErrorPage from '../pages/not-found';
import { Login } from '../pages/login/login';
import { Products } from '../pages/products/products';
import { Home } from '../pages/home';
import { Orders } from '../pages/orders/orders';
import { Users } from '../pages/users/users';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <UnGuardedRoute component={Login} />,
  },
  {
    path: '/users',
    element: <GuardedRoute component={Users} />,
  },
  {
    path: '/products',
    element: <GuardedRoute component={Products} />,
  },
  {
    path: '/products/:productId',
    element: <GuardedRoute component={Products} />,
  },
  {
    path: '/orders',
    element: <GuardedRoute component={Orders} />,
  },
]);
