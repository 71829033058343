import './submit-button.css';

export function SubmitButton({
  label = 'Submit',
  disabled,
}: {
  label?: string;
  disabled?: boolean;
}) {
  return (
    <button className="submit" type="submit" disabled={disabled}>
      {label}
    </button>
  );
}
