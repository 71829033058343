import { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext<{
  isAuth: boolean;
  xCsrfToken: string | null;
  authenticate: (xCsrfToken: string) => void;
  unauthenticate: () => void;
}>({
  isAuth: false,
  xCsrfToken: null,
  authenticate: function () {},
  unauthenticate: function () {},
});

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isAuth, setIsAuth] = useState(false);

  const xCsrfToken = localStorage.getItem('x-csrf-token');

  function authenticate(xCsrfToken: string) {
    localStorage.setItem('x-csrf-token', xCsrfToken);
    setIsAuth(true);
  }

  function unauthenticate() {
    localStorage.removeItem('x-csrf-token');
    setIsAuth(false);
  }

  useEffect(() => {
    let loggedIn = localStorage.getItem('x-csrf-token');
    if (loggedIn) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        xCsrfToken,
        authenticate,
        unauthenticate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
