import './product-list.css';

import { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';

import { getProducts } from '../../requests/products';
import { SnackbarContext } from '../../provider/SnackbarProvider';
import { SummaryCard } from '../summary-card/summary-card';
import { ProductsContext } from '../../provider/ProductsProvider';
import { ProductPreview } from '../../contracts/products';
import { AuthContext } from '../../auth/AuthProvider';

export function ProductList({ className = '' }: { className?: string }) {
  const { xCsrfToken } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);
  const { products, addProducts, totalProducts } = useContext(ProductsContext);

  const productListRef = useRef<HTMLUListElement>(null);

  function handleScroll(e: React.UIEvent<HTMLUListElement, UIEvent>) {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.clientHeight -
          e.currentTarget.scrollTop
      ) < 1 &&
      products.length < totalProducts
    ) {
      getProducts(xCsrfToken as string, `${products.length}`)
        .then(async (response) => {
          if (response.ok) {
            const responseData: { products: ProductPreview[] } =
              await response.json();
            addProducts(responseData.products);
            toggleSnackbar('Successfully retrieved products.');
          } else {
            console.error('An error occurred', await response.json());
            toggleSnackbar('Failed to retrieve products.');
          }
        })
        .catch((e) => {
          console.error(e);
          toggleSnackbar('An error occurred, please try again later.');
        });
    }
  }

  return (
    <div className={'products' + className}>
      <h1 className="products__h1">Stocks</h1>
      <ul
        className="products__product-list"
        ref={productListRef}
        onScroll={handleScroll}
      >
        {products.map(({ productId, productName, stockLevel }) => (
          <li className="products__product-list__li" key={productId}>
            <Link to={`/products/${productId}`}>
              <SummaryCard label={productName} value={stockLevel} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
