import './summary-card.css';

export function SummaryCard({
  label,
  value,
}: {
  label: string;
  value: number;
}) {
  return (
    <div className="summary">
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
}
