import { host } from './products';

export function getUsers(
  xCsrfToken: string,
  pageOffset: string = '0',
  pageSize: string = '50'
) {
  return fetch(
    `${host}/v1/users?pageSize=${pageSize}&pageOffset=${pageOffset}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': xCsrfToken,
      },
    }
  );
}

export function countUsers(xCsrfToken: string) {
  return fetch(`${host}/v1/users/total`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': xCsrfToken,
    },
  });
}
