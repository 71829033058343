import { Layout } from '../../layout/layout';

import { UserList } from '../../components/user-list/user-list';

export function Users() {
  return (
    <Layout>
      <UserList />
    </Layout>
  );
}
