import {
  useState,
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useContext,
} from 'react';

import { ProductPreview } from '../contracts/products';
import { countProducts, getProducts } from '../requests/products';

import { AuthContext } from '../auth/AuthProvider';
import { SnackbarContext } from './SnackbarProvider';

export const ProductsContext = createContext<{
  products: ProductPreview[];
  totalProducts: number;
  addProducts: (items: ProductPreview[]) => void;
}>({
  products: [],
  totalProducts: 0,
  addProducts: function () {},
});

export function ProductsProvider({ children }: { children: ReactNode }) {
  const { xCsrfToken } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);

  const [products, setProducts] = useState<ProductPreview[]>([]);

  function populateProductList(items: ProductPreview[]) {
    setProducts(items);
  }

  function addProducts(items: ProductPreview[]) {
    setProducts((prev) => [...prev, ...items]);
  }

  const totalProductsRef = useRef<number | null>(null);

  useEffect(() => {
    if (totalProductsRef.current !== null) {
      return;
    }

    countProducts(xCsrfToken as string).then(async (response) => {
      if (!response.ok) {
        console.error('An error occurred', await response.json());
        toggleSnackbar('Failed to count the products.');
        return;
      }

      const responseData: { total: number } = await response.json();
      totalProductsRef.current = responseData.total;
      toggleSnackbar('Successfully retrieved products.');
    });
  }, [xCsrfToken]);

  useEffect(() => {
    getProducts(xCsrfToken as string)
      .then(async (response) => {
        if (response.ok) {
          const responseData: { products: ProductPreview[] } =
            await response.json();
          populateProductList(responseData.products);
          toggleSnackbar('Successfully retrieved products.');
        } else {
          console.error('An error occurred', await response.json());
          toggleSnackbar('Failed to retrieve products.');
        }
      })
      .catch((e) => {
        console.error(e);
        toggleSnackbar('An error occurred, please try again later.');
      });
  }, [xCsrfToken]);

  return (
    <ProductsContext.Provider
      value={{
        products,
        totalProducts: totalProductsRef.current ?? 0,
        addProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}
