import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../auth/AuthProvider';
import { SnackbarContext } from '../../provider/SnackbarProvider';

import { getPoductDetails } from '../../requests/products';

import { ProductInfo, ProductPreview } from '../../contracts/products';

export function ProductDetails({ productId }: { productId: string }) {
  const { xCsrfToken } = useContext(AuthContext);
  const { toggleSnackbar } = useContext(SnackbarContext);

  const [productInfo, setProductInfo] = useState<ProductPreview | null>(null);

  useEffect(() => {
    getPoductDetails(xCsrfToken as string, productId).then(async (response) => {
      if (!response.ok) {
        console.error('An error occurred', await response.json());
        toggleSnackbar('Failed to retrieve product.');
        return;
      }

      const { product }: { product: ProductInfo } = await response.json();
      setProductInfo({
        productId: product.productId,
        productName: product.productName,
        sellingPrice: product.sellingPrice,
        stockLevel: product.stockLevel,
      });

      toggleSnackbar('Successfully retrieved product info.');
    });
  }, [productId, xCsrfToken]);

  return (
    <div className="product-details">
      <h2>{productInfo?.productName}</h2>
      <p>
        Selling Price: {productInfo?.sellingPrice}
        <br />
        Stock Level: {productInfo?.stockLevel}
      </p>
    </div>
  );
}
