import { ReactNode, useContext } from 'react';

import { Navbar } from '../components/nav-bar';
import { Snackbar } from '../components/snackbar/snackbar';
import { SnackbarContext } from '../provider/SnackbarProvider';

import './layout.css';

export function Layout({ children }: { children: ReactNode }) {
  const { show } = useContext(SnackbarContext);
  return (
    <>
      <Navbar />
      <main>{children}</main>
      {show && <Snackbar />}
    </>
  );
}
