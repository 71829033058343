import { useContext } from 'react';

import { SnackbarContext } from '../../provider/SnackbarProvider';

import './snackbar.css';

export function Snackbar() {
  const { message } = useContext(SnackbarContext);
  return <div className="snackbar">{message}</div>;
}
