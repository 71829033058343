import './nav-bar.css';
import { useContext, useRef } from 'react';

import burger from '../assets/burger-menu.png';

import { Link, useLocation } from 'react-router-dom';

import { AuthContext } from '../auth/AuthProvider';

import { ImageButton } from './image-button/image-button';

export function Navbar() {
  const { isAuth, unauthenticate } = useContext(AuthContext);
  const { pathname } = useLocation();

  const ulRef = useRef<HTMLUListElement | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const ulContainerRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLElement>(null);

  const handleMenuClick = () => {
    if (ulRef.current!.className === 'nav-bar__ul mobile-hidden') {
      ulRef.current!.className = 'nav-bar__ul mobile-visible';
      ulContainerRef.current!.className =
        'nav-bar__ul-container mobile-visible';
      overlayRef.current!.className = 'nav-bar__overlay mobile-visible';
      navRef.current!.className = 'nav-bar mobile-visible';
      menuRef.current!.className = 'burger-menu mobile-hidden';
    } else {
      ulRef.current!.className = 'nav-bar__ul mobile-hidden';
      ulContainerRef.current!.className = 'nav-bar__ul-container mobile-hidden';
      overlayRef.current!.className = 'nav-bar__overlay mobile-hidden';
      navRef.current!.className = 'nav-bar mobile-hidden';
      menuRef.current!.className = 'burger-menu mobile-visible';
    }
  };

  const hideNavMenu = () => {
    console.log('on blur');
    ulRef.current!.className = 'nav-bar__ul mobile-hidden';
    ulContainerRef.current!.className = 'nav-bar__ul-container mobile-hidden';
    overlayRef.current!.className = 'nav-bar__overlay mobile-hidden';
    navRef.current!.className = 'nav-bar mobile-hidden';
    menuRef.current!.className = 'burger-menu mobile-visible';
  };

  return (
    <nav className="nav-bar mobile-hidden" ref={navRef} onClick={hideNavMenu}>
      <div className="nav-bar__overlay" ref={overlayRef}></div>
      <div
        className="nav-bar__ul-container mobile-hidden"
        ref={ulContainerRef}
        onClick={(e) => e.stopPropagation()}
      >
        <ul
          className="nav-bar__ul mobile-hidden"
          ref={ulRef}
          onBlur={hideNavMenu}
        >
          <li key="home">
            <Link to="/" className="nav-bar__nav-link">
              Home
            </Link>
          </li>
          {!isAuth && pathname !== '/login' && (
            <li key="login">
              <Link to="/login" className="nav-bar__nav-link">
                Login
              </Link>
            </li>
          )}
          {isAuth && pathname !== '/users' && (
            <li key="users">
              <Link to="/users" className="nav-bar__nav-link">
                Users
              </Link>
            </li>
          )}
          {isAuth && pathname !== '/products' && (
            <li key="products">
              <Link to="/products" className="nav-bar__nav-link">
                Products
              </Link>
            </li>
          )}
          {isAuth && pathname !== '/orders' && (
            <li key="orders">
              <Link to="/orders" className="nav-bar__nav-link">
                Orders
              </Link>
            </li>
          )}
          {isAuth && (
            <li key="logout">
              <button
                className="nav-bar__nav-link"
                type="button"
                onClick={unauthenticate}
              >
                Logout
              </button>
            </li>
          )}
        </ul>
      </div>

      <div
        className="burger-menu mobile-visible"
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}
      >
        <ImageButton image={burger} onClick={handleMenuClick} />
      </div>
    </nav>
  );
}
