import './image-button.css';

export function ImageButton({
  onClick,
  image,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  image: string;
}) {
  return (
    <button className="image-button" type="button" onClick={onClick}>
      <img src={image} alt="icon" />
    </button>
  );
}
